#layout-container {
  height: 100vh;

  .pointer {
    cursor: pointer;
  }

  #right-side-block {
    width: calc(100% - 250px);
    background: #fafafa;
  }
  .custom-body-height {
    overflow: auto;
    height: calc(100% - 75px);
  }
  .force-full-width {
    width: 100% !important;
  }
}
