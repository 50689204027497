#Dropdown-container {
  width: 100%;

  .select__control {
    width: 100%;
    height: 48px;
    min-height: 48px;
    padding: 0px 6px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    border-radius: 4px;
    border: 1px solid #222222;
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
    outline: none;

    box-shadow: unset;

    :focus {
      border: unset;
      outline: none;
      box-shadow: unset;
    }

    :active {
      box-shadow: unset;
    }

    option {
      margin: 15px 0px;
      padding: 15px 0px;
      font-size: 15px;
      font-weight: 400;
      line-height: 18px;
      color: var(--black-olive);
    }
  }

  .select__indicator-separator {
    display: none;
  }

  .css-1pahdxg-control {
    border-color: unset;
    outline: none !important;
    height: 45px;
    padding: 0px 6px;
    border-radius: 4px;
    border: 1px solid var(--american-silver);
  }

  .css-4ljt47-MenuList {
    padding: unset;
  }

  .select__option {
    border-bottom: 1px solid #ebeff2;
  }

  .select__option:last-child {
    border-bottom: unset;
  }

  .select__placeholder {
    color: #b7b7b7;
    white-space: nowrap;
  }

  .css-qc6sy-singleValue {
    font-size: 16px;
    font-weight: 400;
    color: black;
  }

  .css-109onse-indicatorSeparator {
    display: none;
  }

  .css-1insrsq-control {
    height: 45px;
  }

  .css-lr9fot-singleValue {
    color: unset;
  }

  .css-26l3qy-menu {
    z-index: 9;
  }
}