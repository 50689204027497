#header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 30px 14px 0px;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.06);
  background: #ffffff;
  .menu-icn-open {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 35px;
    cursor: pointer;
    // margin-right: 30px;
  }
  .right-header-block {
    display: flex;
    align-items: center;

    .notification-block {
      position: relative;
      margin-right: 35px;

      .outer-block {
        padding-left: 2px;
        padding-bottom: 2px;
        position: absolute;
        right: -3px;
        top: -3px;
        background-color: #fff;
        border-radius: 50%;

        .counter-block {
          background-color: #ff481a;
          color: #fff;
          font-size: 10px;
          font-weight: 600;
          line-height: 16.15px;
          height: 13px;
          width: 13px;
          text-align: center;
          border-radius: 50%;
        }
      }
    }

    .profile-header-block {
      background-color: #132c4e;
      height: 42px;
      width: 42px;
      border-radius: 50%;
      margin-right: 8px;
    }

    .user-name-header-block {
      margin-right: 20px;

      .name-block {
        font-size: 15px;
        font-weight: 600;
        line-height: 27px;
        color: #111111;
        display: flex;
        align-items: center;
        gap: 20px;

        i {
          cursor: pointer;
          margin-top: 10px;
        }

        position: relative;

        .profile-option-block {
          position: absolute;
          top: 30px;
          right: 0;
          background-color: #fff;
          font-size: 13px;
          font-weight: 500;
          line-height: 21px;
          color: #414141;
          width: 134px;
          border-radius: 5px;

          .item-block {
            padding: 13px 15px;
            border-bottom: 1px solid #e7e7e7;
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;

            .icon-c-block {
              height: 24px;
              width: 24px;
              display: flex;
            }
          }
        }
      }

      .role-block {
        font-size: 13px;
        font-weight: 500;
        line-height: 21px;
        color: #707070;
      }
    }
  }
}
