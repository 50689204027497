.gallery-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // grid-template-rows: 200px;
    gap: 1rem;
    border-bottom: 1px solid #222222;

    .grid-block {
        border: 1px solid #cfcece;
    }

    .image-container {
        // padding: 10px;
        border-radius: 4px;
        // border: 1px solid #cfcece;
        display: flex;
        justify-content: center;
        align-items: center;

        .image-div {
            height: 150px;
            width: 250px;
            // background-color: red;
            // display: flex;

            .grid-image {
                border-radius: 4px;
                // object-fit: cover;
                // justify-content: center;
            }
        }
    }


}