#left-side-block {
  height: 100%;
  // width: 236px;
  width: 250px;
  background-color: var(--dashboard-primary);

  .menu-icn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 35px;
    cursor: pointer;
    margin-right: 10px;
    height: 70px;
  }

  .top-icon-block {
    padding: 0px 0px 50px 24px;
  }

  .menu-list-container {
    padding-right: 16px;
    overflow: auto;
    height: calc(100vh - 150px);

    .menu-list-item {
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      line-height: 30.6px;
      padding: 8px 26px;
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .active-menu-item {
      font-weight: 600;
      color: #132c4e;
      background-color: #fff;
      border-radius: 0px 10px 10px 0px;
    }

    .inactive-menu-item {
      cursor: pointer;

      img {
        filter: invert(88%) sepia(50%) saturate(0%) hue-rotate(283deg) brightness(108%) contrast(101%);
      }
    }

    .sub-menu-item {
      // background-color: #fff;
      color: #fff;
      border-radius: 0px 10px 0px 10px;
      padding: 8px 26px;
      font-size: 18px;
      font-weight: 500;
      line-height: 30.6px;
      margin-left: 40px;
    }

    .active-sub-menu-item {
      font-weight: 600;
      color: #132c4e;
      background-color: #fff;
    }

    .inactive-sub-menu-item {
      cursor: pointer;
      // img {
      //   filter: invert(88%) sepia(50%) saturate(0%) hue-rotate(283deg)
      //     brightness(108%) contrast(101%);
      // }
    }
  }
}