#user-view-container {

    .status-block {
        height: 48px;
        text-align: center;
        border-radius: 4px;
        font-size: 15px;
        font-weight: 600;
        line-height: 27px;
        border: unset;
    }

    .use-personal-details-container {
        border-top: 1px solid gray;

        .img-sec {
            text-align: right;

            .profile-image {
                width: 150px;
                height: 150px;
            }
        }
    }

    .use-education-details-container {
        border-top: 1px solid gray;
    }

    .button-section {
        .upload-image {
            display: none;
        }

        .upload-text {
            border-radius: 5px;
            border: 1px solid black;
        }
    }


}