#search-input-container {
  // width: 300px;
  position: relative;

  input {
    height: 48px;
    border: 1px solid #718095;
    border-radius: 5px;
    // border: unset;
    // height: 35px;
    // border-left: 1px solid #e5e5e5;
    border-radius: 5px;
    background: #ffffff;
    padding: 8px 16px 8px 16px;
    // border: none;
    width: 100%;

  }

  .searc-icon-block {
    width: 18px;
    height: 18px;
    position: absolute;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
  }
}