#report-search-input-container {
  .r-search-block {
    position: relative;
    .right-icon-block {
      display: flex;
      position: absolute;
      top: 12px;
      right: 12px;
      i {
        cursor: pointer;
        font-size: 22px;
      }
    }
    input {
      height: 48px;
      border: 1px solid #414141;
      border-radius: 5px;
      border-radius: 5px;
      background: #ffffff;
      padding: 8px 16px 8px 16px;
      width: 100%;
    }

    ::placeholder {
      color: #b7b7b7;
      opacity: 1;
      /* Firefox */
    }
  }
}
