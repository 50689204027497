:root {
  --primary: #2176ff;
  --dashboard-primary: #132c4e;
  --white: #ffffff;
  --blue-10: #e2e5e8;
  --black: #000000;
  --black-100: #111111;
  --black-80: #414141;
  --black-60: #707070;
  --black-40: #a0a0a0;
  --blue: #1571ed;
  --blue-60: #718095;
  --black-20: #cfcfcf;
  --blue-crayola: #2563eb;
  --light-gray: #fafafa;
  --blue-5: #eeeff0;
  --green: #4CAF50;
}

$colors: (
  "primary": var(--primary),
  "dashboard-primary": var(--dashboard-primary),
  "white": var(--white),
  "blue-10": var(--blue-10),
  "black-100": var(--black-100),
  "black-80": var(--black-80),
  "black-60": var(--black-60),
  "black-40": var(--black-40),
  "blue": var(--blue),
  "black-20": var(--black-20),
  "black": var(--black),
  "blue-crayola": var(--blue-crayola),
  "blue-60": var(--blue-60),
  "light-gray": var(--light-gray),
  "blue-5": var(--blue-5),
  "green": var(--green),

);

@each $name,
$color in $colors {
  .color-#{$name} {
    color: $color !important;
  }

  .bg-#{$name} {
    background-color: $color !important;
  }
}