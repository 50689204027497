#check-box-container {
  // .form-check:last-child {
  //   margin-bottom: 10px;
  // }

  .form-check-input {
    border: 1px solid #718095;
    border-radius: 2px;
    width: 24px;
    height: 24px;
    margin-top: 0px;
    margin-right: 16px;
    cursor: pointer;

    &:focus {
      box-shadow: unset;
    }
  }
}