#button-container {
  display: flex;

  .button-submit {
    width: 100%;
    height: 48px;
    text-align: center;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    line-height: 27px;
    border: unset;

    &:disabled {
      opacity: 0.5;
    }
  }

  @mixin btn-style($bg-color, $color) {
    background-color: $bg-color;
    color: $color;
  }

  .btn-primary {
    @include btn-style(var(--black-20), #ffffff);
  }

  .btn-dashboard-primary {
    @include btn-style(var(--dashboard-primary), #ffffff);
  }

  .btn-primary-yellow-outline {
    @include btn-style(#ffffff, #31393c);
    border: 1px solid var(--primary-yellow);
  }

  .btn-primary-outer-space {
    @include btn-style(var(--outer-space), #ffffff);
  }

  .btn-primary-darker {
    @include btn-style(#132c4e, #ffffff);
  }

  .btn-primary-darker-disabled {
    @include btn-style(#a0a0a0, #ffffff);
  }

  .btn-primary-darker-outline {
    border: 1px solid #414141;
    border-radius: 5px;
    @include btn-style(#ffffff, #111111);
  }
}