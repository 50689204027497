#sort-btn-block {
    .sort-button {
        display: flex;
        color: var(--dashboard-primary);
        background-color: var(--white);
        border-radius: 5px;
        justify-content: center;
        border: 0.5px solid #718095;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;


    }
}