.table-image-section {
    .image-div-table {
        width: 200px;
        height: 100px;

        .image-table {
            width: 200px;
            height: 100px;
            border-radius: 4px;
        }
    }
}