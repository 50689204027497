#user-edit-container {
    .file-upload-block {
        display: flex;
        align-items: center;
        width: fit-content;
        overflow: hidden;
        border-radius: 4px;

        .choose_file {
            position: relative;
            height: 40px;
            display: flex;
            align-items: center;

            .btn-block {
                padding: 13px 25px;
            }
        }

        .choose_file input[type="file"] {
            -webkit-appearance: none;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
        }
    }

    .button-section {
        .upload-image {
            display: none;
        }

        .upload-text {
            border-radius: 5px;
            border: 1px solid black;
        }
    }
}