#text-input-container {

  // display: flex;
  // width: 100%;
  // background-color: lightcoral;
  .text-input-block {
    position: relative;

    .right-icon-block {
      display: flex;
      position: absolute;
      top: 15px;
      right: 20px;

      i {
        cursor: pointer;
      }
    }
  }

  .text-input {
    width: 100%;
    height: 48px;
    background: #ffffff;
    border: 1px solid #414141;
    border-radius: 5px;
    padding-left: 20px;
    color: black !important;
  }

  ::placeholder {
    color: #a0a0a0;
    /* Firefox */
  }

  :-ms-input-placeholder {
    color: #b7b7b7;
  }

  ::-ms-input-placeholder {
    color: #b7b7b7;
  }

  input[type="text"]:disabled {
    background: #f4f4f4;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}