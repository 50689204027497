@import "./color.scss";

* {
  margin: 0;
  padding: 0;
  font-family: "League Spartan";
  letter-spacing: 0.015em;
}

html {
  scroll-behavior: smooth;
}

.letter-space-15 {
  letter-spacing: 0.01em;
}

@mixin text-font-style($f-size, $f-weight, $line-height) {
  font-size: $f-size !important;
  font-weight: $f-weight !important;
  line-height: $line-height !important;
}

.text-9-500 {
  @include text-font-style(9px, 500, 16px);
}

.text-13-500-21 {
  @include text-font-style(13px, 500, 21px);
}

.text-13-400 {
  @include text-font-style(13px, 400, 27px);
}

.text-13-500 {
  @include text-font-style(13px, 500, 27px);
}

.text-13-600 {
  @include text-font-style(13px, 600, 23px);
}

.text-11-500 {
  @include text-font-style(11px, 500, 20px);
}

.text-15-500 {
  @include text-font-style(15px, 500, 27px);
}

.text-15-700 {
  @include text-font-style(15px, 700, 27px);
}

.text-15-700 {
  @include text-font-style(15px, 700, 27px);
}

.text-16-500 {
  @include text-font-style(16px, 500, 24px);
}

.text-16-600 {
  @include text-font-style(16px, 600, 23px);
}

.text-17-500 {
  @include text-font-style(17px, 500, 31px);
}

.text-17-500-27 {
  @include text-font-style(17px, 500, 27px);
}

.text-17-600 {
  @include text-font-style(17px, 600, 31px);
}

.text-17-700 {
  @include text-font-style(17px, 700, 30px);
}

.text-17-700 {
  @include text-font-style(17px, 700, 31px);
}

.text-19-700 {
  @include text-font-style(19px, 700, 30px);
}

.text-20-700 {
  @include text-font-style(20px, 700, 34px);
}


.text-20-700 {
  @include text-font-style(20px, 700, 34px);
}

.text-22-700 {
  @include text-font-style(22px, 700, 34px);
}

$sizes: 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 21, 22, 23,
  24, 25, 26, 27, 30, 32, 33, 35, 39, 40, 44, 45, 48, 49, 50, 55, 56, 58, 60, 64,
  65, 67, 68, 69, 70, 90, 94, 96, 99, 100, 103, 124, 130, 135, 150, 196, 205,
  409;

@each $size in $sizes {
  .cps-#{$size} {
    padding-left: #{$size}px !important;
  }

  .cpe-#{$size} {
    padding-right: #{$size}px !important;
  }

  .cpt-#{$size} {
    padding-top: #{$size}px !important;
  }

  .cpb-#{$size} {
    padding-bottom: #{$size}px !important;
  }

  .cms-#{$size} {
    margin-left: #{$size}px !important;
  }

  .cme-#{$size} {
    margin-right: #{$size}px !important;
  }

  .cmt-#{$size} {
    margin-top: #{$size}px !important;
  }

  .cmb-#{$size} {
    margin-bottom: #{$size}px !important;
  }
}

.pointer {
  cursor: pointer;
}

.card-effect {
  background-color: #fff;
  border-radius: 10px;
}

.modal-90w {
  width: 80% !important;
  max-width: none !important;
}

.modal-409 {
  width: 409px !important;
  max-width: none !important;
}

.w-fit {
  width: fit-content;
}

.fit-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.fill {
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  overflow: hidden;
}

.fill-image {
  width: 100%;
  height: 100%;
  max-width: 100%;

  // object-fit: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  overflow: hidden;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  border: unset;
  border-color: unset;
}

.dropdown-toggle::after {
  display: none !important;
}

.btn {
  padding: unset !important;
  --bs-btn-border-width: unset !important;
}