#image-gallery-container {

    .image-upload {
        height: 48px;

        label {
            // display: none;
            max-width: unset;
            border: 2px dashed #132c4e;
            height: 100px !important;

            .kyUZVo>span {
                font-size: 18px;
                color: rgb(102, 102, 102);
            }

            .kyUZVo .file-types {
                display: none;
            }
        }
    }

    .operations-block {
        padding-bottom: 5px;
        border-bottom: 1px solid #222222;

        .grid-icon-container,
        .list-icon-container {
            height: 48px;
            display: flex;
            align-items: center;
            font-size: 20px;
            border-radius: 4px;
            border: 1px solid #222222;
            padding: 0px 10px;
        }

    }




    // .table-image-section {
    //     .table-image-div {
    //         width: 100px;
    //         height: 100px;
    //     }
    // }

    .pagination-section {
        .paginate-number-section {
            border: 1px solid #222222;
            border-radius: 4px;
            padding: 0px 10px;
        }
    }
}